import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ChartTooltip from './ChartTooltip';
import {
    frequencyDatesSelector,
    getPlotFrequenciesForRegions, getPlotPredictions, getPlotPredictionsForRegions, getSeqCaseCountsForRegions,
    getFrequenciesStatusesForStrainSubsets, getLeftPadding,
    getPredictionsStatusesForStrainSubsets
} from '../../redux/selectors/frequenciesSelector';
import { getColorBins } from '../../redux/selectors/metadataSelector';
import { getChartStatus, getFrequencyChartStatus, getIsMobile } from '../../redux/selectors/statusSelector';
import FrequencyChartD3 from './helpers/FrequencyChartD3';
import { getNodeWidth, getNodeHeight } from '../../functions/cssHelpers';
import { fetchClades } from '../../redux/actions/cladeActions';
import { fetchPredictions } from '../../redux/actions/predictionsActions';
import { setRenderStatus, setComponentStatus } from '../../redux/actions/renderActions';
import { COMPONENTS } from '../../config/consts';
import { useEventListener } from 'usehooks-ts';
import { useDebouncedCallback } from '../../functions/customHooks';
import { emptyObject } from '../../functions/functions';

// import { fetchRegionMultiplicities } from '../../actions/predictionsActions';

const styles = theme => ({
    graph: {
        padding: '0px 20px 20px 20px',
        height: 'calc(100% - 80px)',

    },
    border: {
        border: 'solid 1px #c1c1c1'
    },
    graphMobile: {
        padding: '2px 0px 20px 0px',
        height: 'calc(100% - 80px)'
    },
    graphExport: {
        padding: 0,
        boxSizing: 'border-box',
        height: '100%',
        width: '100%'
    },
    multiexport: {
        // padding: '30px 0',
        height: '100%',
    }
});

const FrequenciesChart = props => {
    const { ownPropsRegionId, width, height, exportMode, multiexport, chartLayout, plotType, strainSubset, visibleBins, minLogSpace, displayErrorBars,
        displayGreyZone, subsetId, loading, hiddenMenu, logSpace, viewName, classes, title, isMobile, cladesStatus, visibleBinsLoaded, //frequenciesPlotData,
        predictionsPlotData,leftPadding } = props;

    const handleTooltipPositionChange = (x, y, info) => {
        setToolTipPos({ x, y });
        setInfo(info);
        // this.setState({ tooltipPos: { x, y }, info });
    }

    const updateDimensions = () => {
        if (_element.current) {
            const mountNode = _element.current;
            const { parentNode } = mountNode;
            const width = getNodeWidth(parentNode);
            const height = getNodeHeight(parentNode);
            chartD3.current.resizeComponent(width, height);
        }
    };

    const debouncedResize = useDebouncedCallback(() => {
        updateDimensions();
    }, 200);


    if (!exportMode) {
        useEventListener('resize', debouncedResize);
    }


    const getComponentId = () => {
        const { ownPropsRegionId } = props;
        return (ownPropsRegionId ? `frequencies_${ownPropsRegionId}_svg` : 'frequencies_svg');
    }


    const _element = useRef();
    const componentId = useRef(`${COMPONENTS.FREQ_CHART}_${new Date().getTime()}_${subsetId}`);
    const chartD3 = useRef(new FrequencyChartD3(componentId, handleTooltipPositionChange));
    const [tooltipPos, setToolTipPos] = useState({ x: null, y: null });
    const [info, setInfo] = useState();

    const init = (longest) => {
        const mountNode = _element.current;
        const { parentNode } = mountNode;
        const widthChart = (exportMode && !multiexport) ? width - 250 + longest : getNodeWidth(parentNode);
        const heightChart = (exportMode && !multiexport) ? height - 50 : getNodeHeight(parentNode);

        chartD3.current.setProps(props);
        chartD3.current.setMountNode(mountNode);
        chartD3.current.setComponentName(`binFrequencies${ownPropsRegionId || ''}`); 
        chartD3.current.setHeight(heightChart);
        chartD3.current.setWidth(widthChart);
        chartD3.current.prepareGraphArea(); 

        return () => {
            setToolTipPos({ x: null, y: null });
            setInfo();
        }
    }

    useEffect(() => {
        if (exportMode && !multiexport)
            return;
        else 
            init(0) 
    }, [])
 
    useEffect(() => {
        chartD3.current.setProps(props);
    });

    useEffect(() => {
        if (cladesStatus === 'loaded' && exportMode && !multiexport && !emptyObject(visibleBins)) {
            const longest = chartD3.current.getLongestLabel();
            init(longest)
        }
    }, [cladesStatus, visibleBinsLoaded]);

    useEffect(() => {
        if (loading) return;
        updateDimensions();
    }, [hiddenMenu]);


    useEffect(() => {
        chartD3.current.removePlots();
        if (loading) return;
        chartD3.current.renderD3Component(viewName, componentId.current, loading, logSpace);
    }, [chartLayout]);

    useEffect(() => {
         //if (freqCategory !== prevProps.freqCategory || (freqCategory === 'tcellAntigenicity' && (gene !== prevProps.gene || hla !== prevProps.hla))) return;
        if (loading) {
            chartD3.current.removePlots();
            return;
        }

        chartD3.current.setLeftPadding(leftPadding);
        chartD3.current.changeAxes();
        chartD3.current.renderD3Component(viewName, componentId.current, loading, logSpace);

    }, [loading, plotType, logSpace, strainSubset, visibleBins, minLogSpace, displayErrorBars, displayGreyZone, predictionsPlotData]);
     // nonStackedPlot, stackedPlot, selectedBins, maxMultiplicity, maxStackedMultiplicity, dataPointsFrequencies, dataPointsMultiplicities]);



    const style = multiexport
        ? classes.multiexport
        : exportMode
            ? classes.graphExport
            : isMobile
                ? classes.graphMobile
                : classes.graph;
    
                
    return (
        <div className={style}>
            {title && <div className={classes.title}>{title}</div>}
            <svg className={`${classes.border} svg-bg`} id={getComponentId()} ref={_element} />
            <ChartTooltip position={tooltipPos} info={info} />
        </div>
    );
};

// Mapping our Redux State to Props
FrequenciesChart.propTypes = {
    loading: PropTypes.bool,
    chartLayout: PropTypes.string,
    lineage: PropTypes.string,
    classes: PropTypes.shape({ graph: PropTypes.string }),
    frequenciesStatus: PropTypes.string,
    freqCategory: PropTypes.string,
    gene: PropTypes.string,
    hla: PropTypes.string,
    viewName: PropTypes.string,
    ownPropsRegionId: PropTypes.string,
    showPrediction: PropTypes.bool,
};

FrequenciesChart.defaultProps = {
    showPrediction: true
};

const mapStateToProps = (state, ownProps) => {
    // console.log('[FrequenciesChart] 1');
    const { clades, cladesStatus } = state.cladeData;
    const { freqCategory, regions, displayErrorBars, displayGreyZone,
        plotType, chartLayout, lineage, exportMode, gene, hla, binMethod,
        width, height, binCnt, modelId, strainSubset, minLogSpace, intro } = state.parameters;
    const subsetId = ownProps.regionId || strainSubset;
    const { visibleBins, logSpace } = state.parameters;
    const { trackingTo, startTime, endTime, predictionBaseline } = frequencyDatesSelector(state);

    const frequenciesPlotDataRegions = getPlotFrequenciesForRegions(state);

    const frequenciesPlotData = frequenciesPlotDataRegions[subsetId];// || {};

    const { nonStackedPlot, stackedPlot, selectedBins, maxMultiplicity, maxStackedMultiplicity, dataPointsFrequencies, dataPointsMultiplicities } = (frequenciesPlotData || {});
    //console.log(state.allRegions, ownProps.regionId, state.subsetId, state.regionId, ownProps.multiexport);
    const predictionsPlotData = ownProps.regionId ? getPlotPredictionsForRegions(state)?.[ownProps.regionId] : getPlotPredictions(state);
    const stackedPredictions = predictionsPlotData?.[0]?.data.stackedPredictions;

    const leftPadding = getLeftPadding(state)
    const frequenciesStatusesRegions = getFrequenciesStatusesForStrainSubsets(state);
    // console.log('[FrequenciesChart] frequenciesStatusesRegions = ',frequenciesStatusesRegions)
    const frequenciesStatus = frequenciesStatusesRegions[subsetId];
    const predictionsStatusesRegions = getPredictionsStatusesForStrainSubsets(state);
   
    //console.log('[FrequenciesChart] predictionsStatusesRegions = ',predictionsStatusesRegions)
    const predictionsStatus = predictionsStatusesRegions[subsetId];
    //console.log('predictionsStatus = ',predictionsStatus);
    const seqCaseCountsRegions = getSeqCaseCountsForRegions(state);
    const seqCaseCounts = seqCaseCountsRegions[subsetId];
    const visibleBinsLoaded = !emptyObject(visibleBins);
    // const isLoading = frequenciesStatus !== 'loaded' || frequenciesPlotData === null || predictionsPlotData === null; // || getFrequencyChartStatus(state);
    const colorBins = getColorBins('freqCategory')(state);
    const showPrediction = (ownProps.showPrediction === undefined ? state.parameters.showPrediction : ownProps.showPrediction);
    //console.log(`showPrediction = ${showPrediction}`);
    // const loading = getFrequencyChartStatus(state) || frequenciesStatus !== 'loaded' || (!(predictionsStatus === 'loaded' || predictionsStatus === 'nodata') && showPrediction);
    
    const loading = getFrequencyChartStatus(state) || frequenciesStatus !== 'loaded' || (!((predictionsStatus === 'loaded' || predictionsStatus === 'nodata') || intro) && showPrediction);
    
    //console.log('loading', loading, 'predictionsStatus', predictionsStatus)
    //console.log('frequenciesPlotData', frequenciesPlotData)
    //console.log('predictionsPlotData', predictionsPlotData)
    //console.log(`loading = ${loading}, showPrediction = ${showPrediction}, predictionsStatus = ${predictionsStatus}, frequenciesStatus = ${frequenciesStatus}, getFrequencyChartStatus(state) = ${getFrequencyChartStatus(state)}`);
    // console.log(`subsetId = ${subsetId}, frequenciesStatus = ${frequenciesStatus}, predictionsStatus = ${predictionsStatus}, showPrediction = ${showPrediction}`);
    //  console.log('[FrequenciesChart] 2');
    return ({
        lineage,
        freqCategory,
        startTime,
        endTime,
        trackingTo,
        predictionBaseline,
        gene,
        hla,
        subsetId,
        // regionId,
        modelId,
        ownPropsRegionId: ownProps.regionId,
        clades,
        regions,
        binMethod,
        binCnt,
        colorBins,
        width,
        height,
        frequenciesStatus, // : frequenciesStatusSelector(state),
        predictionsStatus,
        nonStackedPlot,
        stackedPlot,
        visibleBins,
        logSpace,
        minLogSpace,
        selectedBins,
        stackedPredictions,
        seqCaseCounts,
        displayErrorBars: !intro ? displayErrorBars : false,
        displayGreyZone,
        plotType,
        cladesStatus,
        chartLayout,
        maxMultiplicity,
        maxStackedMultiplicity,
        dataPoints: plotType === 'frequencies' ? dataPointsFrequencies : dataPointsMultiplicities,
        loading,
        exportMode,
        //treeDataStatus: state.treeData.treeDataStatus,
        showPrediction,
        multiexport: ownProps.multiexport,
        hiddenMenu: state.render.hiddenMenu,
        isMobile: getIsMobile(),
       //frequenciesPlotData,
        predictionsPlotData,
        visibleBinsLoaded,
        leftPadding
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchClades,
    fetchPredictions,
    setRenderStatus,
    setComponentStatus,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FrequenciesChart));
