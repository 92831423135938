import React, { PureComponent, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import { frequencyDatesSelector, getLeftPadding, getPlotFrequencies, getPlotFrequenciesForRegions } from '../../redux/selectors/frequenciesSelector';
import { getChartStatus, getIsMobile } from '../../redux/selectors/statusSelector';
import SequencesChartD3 from './helpers/SequencesChartD3';
import { getNodeWidth, getNodeHeight } from '../../functions/cssHelpers';
import { fetchClades } from '../../redux/actions/cladeActions';
import { setRenderStatus, setComponentStatus } from '../../redux/actions/renderActions';
import { COMPONENTS } from '../../config/consts';
import { useEventListener } from 'usehooks-ts';
import { debounce } from 'lodash';
import { useDebouncedCallback } from '../../functions/customHooks';

const styles = theme => ({
    graph: {
        padding: '0px 20px 20px 20px',
        minHeight: '300px',
        height: '320px',
    },
    graphMobile: {
        padding: '2px 0px 20px 0px',
        minHeight: '300px',
        height: '320px',
    },
    multiexport: {
        // height: '150px',
        // margin: '30px 0',
        height: '100%',
    },
    // title: {
    //     padding: '8px',
    //     borderStyle: 'solid solid none solid',
    //     // borderColor: 'rgba(0, 0, 0, 0.3)',
    //     borderWidth: '0.5px',
    //     boxShadow: '0 1px 1px -1px rgba(0, 0, 0, 0.3)'
    // }
    border: {
        border: 'solid 1px #c1c1c1'
    },

});

const SequencesChart = props => {
    const { ownPropsRegionId, width, height, exportMode, multiexport, chartLayout, plotType, strainSubset, visibleBins, minLogSpace, displayErrorBars,
        displayGreyZone, subsetId, type, loading, hiddenMenu, logSpace, viewName, classes, leftPadding, isMobile } = props;

    // state = {
    //     tooltipPos: { x: null, y: null },
    //     info: null
    // };
    //componentId = `${COMPONENTS.SEQ_CHART}_${new Date().getTime()}`;


    const _element = useRef();

    const componentId = useRef(`${COMPONENTS.SEQ_CHART}_${new Date().getTime()}_${subsetId}`);

    //_element = React.createRef();

    // chartRendered = false;

    //chartD3 = new SequencesChartD3(this, this.props.type);

    const chartD3 = useRef(new SequencesChartD3(type));

    const updateDimensions = () => {
        if (_element.current) {
            const mountNode = _element.current;
            const { parentNode } = mountNode;
            const width = getNodeWidth(parentNode);
            const height = getNodeHeight(parentNode);
            chartD3.current.resizeComponent(width, height);
        }
    };

    const debouncedResize = useDebouncedCallback(() => {
        updateDimensions();
    }, 200);

    if (!exportMode) {
        useEventListener('resize', debouncedResize);
    }


    useEffect(() => {
        const mountNode = _element.current;
        const { parentNode } = mountNode;
        const width = getNodeWidth(parentNode);
        const height = getNodeHeight(parentNode);

        chartD3.current.setProps(props);
        chartD3.current.setMountNode(mountNode);
        chartD3.current.setComponentName(`${type || ''}${ownPropsRegionId || ''}Chart`);
        chartD3.current.setHeight(height);
        chartD3.current.setWidth(width);

        chartD3.current.prepareGraphArea();
    }, [])

    useEffect(() => {
        chartD3.current.setProps(props);
        if (loading) {
            chartD3.current.removePlots();
            return;
        }
        if (leftPadding){
            chartD3.current.setLeftPadding(leftPadding)
            chartD3.current.changeAxes();
        }


        
        chartD3.current.renderD3Component(viewName, componentId.current, loading);
    });

    useEffect(() => {
        if (loading) return;
        updateDimensions();
    }, [hiddenMenu]);


    // useEffect(() => {
    //     if (!leftPadding)
    //         return
    //     console.log(leftPadding)

    //     chartD3.current.setLeftPadding(leftPadding)
    //     chartD3.current.changeAxes();

    // }, [leftPadding]);

    const style = multiexport
        ? classes.multiexport
        : isMobile
            ? classes.graphMobile
            : classes.graph

    return (
        <div className={style}>
            {/* {title && <div className={classes.title}>{title}</div>} */}
            <svg id={`${type}_svg`} className={`${classes.border} svg-bg`} ref={_element} />
            {/* <ChartTooltip position={tooltipPos} info={info} /> */}
        </div>
    );

}

// Mapping our Redux State to Props

SequencesChart.propTypes = {
    loading: PropTypes.bool,
    lineage: PropTypes.string,
    ownPropsRegionId: PropTypes.string,
    type: PropTypes.string,
    viewName: PropTypes.string,
    classes: PropTypes.shape({ graph: PropTypes.string, title: PropTypes.string })
};

const mapStateToProps = (state, ownProps) => {
    // const { clades } = state.cladeData;
    const { type } = ownProps;
    const { lineage, exportMode } = state.parameters;
    const subsetId = ownProps.regionId || state.parameters.strainSubset;

    const frequenciesPlotDataRegions = getPlotFrequenciesForRegions(state);
    const frequenciesPlotData = frequenciesPlotDataRegions[subsetId] || {};
    const { maxSequencesVal, maxCasesVal, sequencesPlot, casesPlot } = frequenciesPlotData;

    //console.log(casesPlot);
    const { startTime, endTime, trackingTo, predictionBaseline } = frequencyDatesSelector(state);
    const frequenciesStatusesForRegion = state.frequenciesData.frequenciesStatus;
    const loading = getChartStatus(state) || frequenciesStatusesForRegion[subsetId] !== 'loaded';

    const maxVal = ((type === 'seq') ? maxSequencesVal : maxCasesVal) || 0;
    const leftPadding = getLeftPadding(state);

    return ({
        type,
        lineage,
        subsetId,
        ownPropsRegionId: ownProps.regionId,
        startTime,
        endTime: trackingTo,
        predictionBaseline,
        maxVal: maxVal, //add epsilon in order to fit the values in the chart area
        data: (type === 'seq') ? sequencesPlot : casesPlot,
        loading,
        exportMode,
        title: ownProps.title,
        multiexport: ownProps.multiexport,
        hiddenMenu: state.render.hiddenMenu,
        isMobile: getIsMobile(),
        leftPadding
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchClades,
    setRenderStatus,
    setComponentStatus,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SequencesChart));
